import React, { useState } from 'react';
import '../../styles/admin/toggleComponent.css';
import SelectedModifyPrices from './SelectedModifyPrices.jsx';
import ModifyGroup from './ModifyGroup.jsx';
import ModifyGroupDescription from './description/modifyGroupDescription.jsx';
import CategoryGroupModify from './category/categoryGroupModify.jsx';

const ToggleComponent = () => {
  const [currentComponent, setCurrentComponent] = useState(0);

  return (
    <div className="toggle-container">
      <div className="button-container">
        <button 
          className={`toggle-button ${currentComponent === 0 ? 'active' : ''}`} 
          onClick={() => setCurrentComponent(0)}
        >
          Modificar todos o por categoría
        </button>
        <button 
          className={`toggle-button ${currentComponent === 1 ? 'active' : ''}`} 
          onClick={() => setCurrentComponent(1)}
        >
          Modificar precios
        </button>
        <button 
          className={`toggle-button ${currentComponent === 2 ? 'active' : ''}`} 
          onClick={() => setCurrentComponent(2)}
        >
          Modificar descripciones 
        </button>
        <button 
          className={`toggle-button ${currentComponent === 3 ? 'active' : ''}`} 
          onClick={() => setCurrentComponent(3)}
        >
          Modificar categorías
        </button>
      </div>

      <div className="component-display">
        {currentComponent === 0 && (
          <div className="first-component">
            <ModifyGroup />
          </div>
        )}
        {currentComponent === 1 && (
          <div className="second-component">
            <SelectedModifyPrices />
          </div>
        )}
        {currentComponent === 2 && (
          <div className="third-component">
            <ModifyGroupDescription />
          </div>
        )}
        {currentComponent === 3 && (
          <div className="fourth-component">
            <CategoryGroupModify />
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleComponent;
