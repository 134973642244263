import React, { useState } from 'react'; 
import { REACT_APP_HOST_HOOKS_DEV } from '../../../config/config.js';
import '../../../styles/category/selectedModifyCategory.css'
import useProductsAdmin from '../../../hooks/useProductsAdmin.js';

const CategoryGroupModify = () => {
  const { products, loading,  refetchProducts } = useProductsAdmin();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [category, setCategory] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleRemoveSelectedProduct = (productId) => {
    setSelectedProducts((prev) => prev.filter((id) => id !== productId));
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const resetForm = () => {
    setSelectedProducts([]);
    setCategory('');
  };

  const handleSubmit = async () => {
    if (!category) {
      setResponseMessage('Por favor, ingresá una categoría válida.');
      return;
    }

    if (selectedProducts.length < 1) {
      setResponseMessage('Por favor, seleccioná productos.');
      return;
    }

    if (!window.confirm(`¿Estás seguro de que quieres modificar la categoría de ${selectedProducts.length} productos?`)) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/categories/modify-category-group`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productIds: selectedProducts,
          category,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(`Categorías modificadas.`);
        resetForm();
        refetchProducts();
      }

      return data
    } catch (error) {
      setResponseMessage('Error al actualizar las categorías.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="categoriesAddContainer">
      <div>
        <h1 className='categoryAddTitle'>Modificar categoría de productos seleccionados</h1>

        <div className='subcontainer-label'>
          <div className="modify-category-form">
            <label>
              Nueva Categoría:
              <input
                type="text"
                className='categoryInput'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder="Escribe la nueva categoría"
              />
            </label>
          </div>

          <div className="modify-category-form search-container">
            <label>
              Buscar producto:
              <input
                type="text"
                placeholder="Escribe el nombre del producto..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>



        {loading ? (
          <div className="category-spinner"></div>
        ) : (
          <>

            <div className="product-category-selection">
              {filteredProducts.map((product) => (
                <div key={product._id} className="product-category-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product._id)}
                      onChange={() => handleCheckboxChange(product._id)}
                    />
                    <span className="category-title">{product.title}</span> - Categoría actual: {product.category}
                  </label>
                </div>
              ))}
              {filteredProducts.length === 0 && (
                <p className="no-category-results">No se encontraron productos con ese nombre.</p>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        {selectedProducts.length > 0 && (
          <div className="selected-category-container">
            <h3>Productos seleccionados</h3>
            {selectedProducts.map((productId) => {
              const product = products.find((p) => p._id === productId);
              return (
                <div key={productId} className="selected-category-item">
                  <span>{product.title}</span>
                  <span>Categoría actual: {product.category}</span>
                  <button className="remove-selected-btn" onClick={() => handleRemoveSelectedProduct(productId)}>
                    ❌
                  </button>
                </div>
              );
            })}
          </div>
        )}

        <button className='btn-modify-category' onClick={handleSubmit}>{isLoading ? 'Cargando...' : 'Actualizar categoría'}</button>
        {responseMessage && <p className="category-response-message">{responseMessage}</p>}
      </div>
    </section>
  );
};

export default CategoryGroupModify;
