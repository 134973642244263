import React, { useState } from 'react';
import useProductsAdmin from '../../../hooks/useProductsAdmin.js';
import '../../../styles/admin/description/modifyGroupDescription.css';
import { REACT_APP_HOST_HOOKS_DEV } from '../../../config/config.js';
import { ToastContainer, toast } from 'react-toastify';

const ModifyGroupDescription = () => {
  const { products, refetchProducts, loading } = useProductsAdmin();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newDescription, setNewDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // 🔍 Estado para la búsqueda

  const handleSelectProduct = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]
    );
  };

  const handleRemoveSelectedProduct = (productId) => {
    setSelectedProducts((prev) => prev.filter((id) => id !== productId));
  };

  const modifyDescription = async () => {

    if (selectedProducts.length === 0) {
      toast.error(`Seleccioná productos por favor.`);
      return;
    }

    if (!newDescription) {
      toast.error(`Escribí una descripción por favor.`);
      return;
    }

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products/modify-group-description`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productIds: selectedProducts, description: newDescription }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Descripciones actualizadas correctamente!');
        refetchProducts();
        setNewDescription('');
        setSelectedProducts([]);
      } else {
        toast.error(`Error al modificar descripción:', ${data.message}`);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  // 🔍 Filtrar productos según la búsqueda
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    <div className="modify-group-container">
      <h2 className="modify-group-title">Modificar Descripción de Productos</h2>

      <textarea
        className="modify-group-textarea"
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
        placeholder="Nueva descripción..."
      />
      <button className="modify-group-button" onClick={modifyDescription} disabled={loading}>
        Modificar Descripción
      </button>

      {selectedProducts.length > 0 && (
        <div className="selected-products-container">
          <h3>Productos Seleccionados</h3>
          <div className="selected-products-subcontainer">
            <ul className="selected-products-list">
              {selectedProducts.map((id) => {
                const product = products.find((p) => p._id === id);
                return (
                  product && (
                    <li key={id} className="selected-product-item">
                      <span className="selected-product-title">{product.title}</span>
                      <button className="remove-selected-btn" onClick={() => handleRemoveSelectedProduct(id)}>
                        ❌
                      </button>
                    </li>
                  )
                );
              })}
            </ul>
          </div>

        </div>
      )}

      <input
        type="text"
        className="modify-group-search"
        placeholder="Buscar producto..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <ul className="modify-group-list">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <li key={product._id} className="modify-group-item">
              <input
                type="checkbox"
                className="modify-group-checkbox"
                checked={selectedProducts.includes(product._id)}
                onChange={() => handleSelectProduct(product._id)}
              />
              <span className="modify-group-product-title">{product.title}</span> - 
              <span className="modify-group-product-desc">{product.description}</span>
            </li>
          ))
        ) : (
          <li className="modify-group-no-results">No se encontraron productos.</li>
        )}
      </ul>

    </div>
  
    <ToastContainer />
    </>
  );
};

export default ModifyGroupDescription;
